<template>
  <div>1</div>
</template>

<script>
export default {
  name: 'Hardness',
  data() {
    return {
      loading: false,
    };
  },
  computed: {
    partId() {
      return this.$store.state.tech.partId;
    },
  },
  created() {
    const roughnessRa = new Promise((resolve) => {
      this.$store.dispatch('tech/getRoughnessRa').then(() => {
        resolve();
      });
    });
    const roughnessRz = new Promise((resolve) => {
      this.$store.dispatch('tech/getRoughnessRz').then(() => {
        resolve();
      });
    });
    Promise.all([roughnessRa, roughnessRz]).then(() => {
      this.$store.dispatch('tech/getHardness', {
        hash: this.partId.hash,
        time_stamp: this.partId.time_stamp,
        file_name: this.partId.file_name,
      }).then(() => {
        this.loading = true;
      });
    });
  },
};
</script>
